import React, { Suspense } from "react";
import Box from "@mui/material/Box";
// import { Outlet } from 'react-router-dom';
// import './style.scss';
import { Spacing } from "@hkexpressairwayslimited/ui";
import classNames from "classnames";

export interface ContentContainerProps {
  children: any;
}

const ContentContainer = (props: ContentContainerProps) => {
  return (
    <Box display='flex' justifyContent='center' className={classNames(Spacing.mb48)}>
      <Box display='flex' width={{ xs: "900px" }}>
        {props.children}
      </Box>
    </Box>
  );
};

export default ContentContainer;
