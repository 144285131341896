"use client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import classNames from "classnames";
import { Spacing, Typography } from "@hkexpressairwayslimited/ui";
import ContentContainer from "../content";
import { useTransContent } from "modules/common/trans-content/transContent";

export enum AlertLevel {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export interface PageAlert {
  content: string;
  alertLevel: AlertLevel;
}

export interface PageContainerProps {
  title: string;
  children: any;
}

const PageContainer = (props: PageContainerProps) => {
  const { t } = useTransContent();

  /**
   * Page level alert
   * setPageAlert callback will be assigned to children props
   */
  const [alert, setAlert] = useState<PageAlert | null>();

  const setPageAlert = useCallback((pageAlert: PageAlert | null) => {
    setAlert(pageAlert);
  }, []);

  const childrenWithProps = useMemo(() => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement<any>(child, { setPageAlert });
      }
      return child;
    });
  }, [props.children, setPageAlert]);

  return (
    <ContentContainer>
      <Grid container columns={12} className={classNames(Spacing.ml32, Spacing.mr32)}>
        {/** Page title */}
        <Grid item xs={12}>
          <h1 className={classNames(Typography.H1)}>{props.title}</h1>
        </Grid>

        {/** Page alert */}
        {alert && (
          <Grid item xs={12}>
            <Alert severity={alert.alertLevel} className={classNames(Spacing.mb16)}>
              {t(alert.content)}
            </Alert>
          </Grid>
        )}

        {/** Page content */}
        <Grid item xs={12}>
          {childrenWithProps}
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default PageContainer;
