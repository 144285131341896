import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { closeErrorDialog } from "modules/common/error-dialog/actions/ErrorDialogAction";
import { useTransContent } from "modules/common/trans-content/transContent";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.neutral.disable,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface ErrorDialogProps {
  title: string | undefined;
  content: string | undefined;
  onCloseCb: () => void;
}

export function ErrorDialog(props: ErrorDialogProps) {
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const { t } = useTransContent();

  const handleClose = () => {
    setOpen(false);
    props.onCloseCb && props.onCloseCb();
    setTimeout(() => {
      dispatch(closeErrorDialog());
    }, 500);
  };

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        {props?.title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{props?.content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t("web.modal.btn.close")}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
