import React, { useEffect, useMemo } from "react";
import { selectErrorDialog } from "modules/common/error-dialog/selectors/errorModalSelector";
import { ErrorDialog } from "modules/common/error-dialog/components/ErrorDialog";
import { useSelector } from "react-redux";

const ErrorDialogContainer = (props: any) => {
  const errorDialog = useSelector(selectErrorDialog);

  return <>{errorDialog.isShow && <ErrorDialog {...errorDialog} />}</>;
};

export default ErrorDialogContainer;
