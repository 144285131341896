// import { authApis } from "./apis/authApi";
// import { dataFetchingTemplateApis } from "./apis/dataFetchingTemplateApi";
// import { flightSelectApis } from "./apis/flightSelectApi";
// import { formTemplateApis } from "./apis/formTemplateApi";
// import { memberApis } from "./apis/memberApi";
import { contentApis } from "./apis/contentApi";
// import { mmbFlowApis } from "./apis/mmbFlowApi";
// import { paymentApis } from "./apis/paymentApi";
// import { travelCompanionApi } from "./apis/travelCompanionApi";

export const handlers = [
  // ...memberApis,
  // ...authApis,
  ...contentApis,
  // ...formTemplateApis,
  // ...dataFetchingTemplateApis,
  // ...flightSelectApis,
  // ...travelCompanionApi,
  // ...paymentApis,
  // ...mmbFlowApis,
];
