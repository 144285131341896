import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
  AccountIcon,
  CURRENCY_OPTIONS,
  CathayIcon,
  Header,
  HeaderPropsType,
  Image,
  OnlineCheckInIcon,
  SearchIcon,
  SelectOption,
  getCookieDomain,
} from "@hkexpressairwayslimited/ui";
import { Link } from "@mui/material";
import { envConfig } from "env";
import { changeLang, getLangLocale } from "modules/common/common/utils/i18n-utils";
import { SUPPORTED_LANGUAGES_WITH_LOCALES } from "modules/common/i18n/conf";
import { useTransContent } from "modules/common/trans-content/transContent";
import { logout } from "modules/features/auth/actions/authAction";
import { selectTokenProfile } from "modules/features/member/selector";
import { changeSelectedCurrency } from "modules/global/currency/service";
import { openSignInPopup, selectCurrencyOptionsShown } from "modules/global/general";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { clearAgentToken, selectAgentProfile, selectEntered } from "store/sessionStorage/slices/b2bSlice";
import { selectCurrency } from "store/sessionStorage/slices/currency";

const HeaderContainer = () => {
  const dispatch = useDispatch();

  const router = useRouter();
  const { t } = useTransContent();
  const tokenProfile = useSelector(selectTokenProfile);
  const agentProfile = useSelector(selectAgentProfile);
  const locale = getLangLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const changeLanguage = useCallback(
    (lang: string) => {
      changeLang(lang, pathname, searchParams.toString());
    },
    [pathname, searchParams]
  );
  const globalSelectedCurrency = useSelector(selectCurrency);
  const enteredB2b = useSelector(selectEntered);
  const currencyOptionsShown = useSelector(selectCurrencyOptionsShown);

  const onClickLogout = () => {
    if (enteredB2b) {
      dispatch(clearAgentToken());
    } else {
      dispatch(logout());
    }
  };

  const currencyOptions = useMemo(
    () =>
      [{ value: envConfig.defaultCurrency }, ...CURRENCY_OPTIONS]
        .filter((option) => option.value !== globalSelectedCurrency)
        .map((rate) => ({
          value: rate.value,
          label: t(`web.general.currency.options.${rate.value}`),
          onChange: (option: SelectOption) => {
            console.log("getCookieDomain", getCookieDomain());
            changeSelectedCurrency(option.value);
          },
        })),
    [globalSelectedCurrency, t]
  );

  const title = useMemo(
    () =>
      ((tokenProfile &&
        t(`web.general.title.options.${tokenProfile?.title}`, { defaultValue: tokenProfile?.title })) as string) || "",
    [t, tokenProfile]
  );

  const otherProps: Partial<HeaderPropsType> = useMemo(
    () => ({
      ...(enteredB2b
        ? {
            variant: Header.Variants.AgentPortal,
            ...(agentProfile
              ? {
                  profile: {
                    fullName: agentProfile.name,
                    credit_account: agentProfile.credit_account,
                  },
                  logoutLabel: t("web.header.booking.logoutLabel"),
                  membershipMenu: [
                    {
                      label: t("web.header.b2b.membershipMenu.agent"),
                      itemProps: {
                        leadingIcon: <OnlineCheckInIcon />,
                        onClick: () => {
                          router.push(`/b2b/agent-portal`);
                        },
                      },
                    },
                    {
                      label: t("web.header.b2b.membershipMenu.account"),
                      itemProps: {
                        leadingIcon: <AccountIcon />,
                        onClick: () => {
                          router.push(`/b2b/account`);
                        },
                      },
                    },
                    {
                      label: t("web.header.b2b.membershipMenu.SearchFlight"),
                      itemProps: {
                        leadingIcon: <SearchIcon />,
                        onClick: () => {
                          router.push(`/b2b/search-flight`);
                        },
                      },
                    },
                  ],
                }
              : {}),
          }
        : {
            loginLabel: t("web.header.booking.loginLabel"),
            logoutLabel: t("web.header.booking.logoutLabel"),
            signInMenu: [
              {
                label: t("web.header.booking.signInMenu.SignUp"),
                itemProps: {
                  onClick: () => {
                    router.push(`/member/signup`);
                  },
                },
              },
              {
                label: t("web.header.booking.signInMenu.Login"),
                itemProps: {
                  onClick: () => {
                    dispatch(openSignInPopup());
                  },
                },
              },
            ],
            ...(tokenProfile
              ? {
                  profile: {
                    title,
                    fullName: `${tokenProfile.given_name} ${tokenProfile.family_name}`,
                    lastName: tokenProfile.family_name,
                  },
                  membershipMenu: [
                    {
                      label: t("web.header.booking.membershipMenu.MyBookings"),
                      itemProps: {
                        leadingIcon: <OnlineCheckInIcon />,
                        onClick: () => router.push(`/manage-my-booking/my-trips`),
                      },
                    },
                    {
                      label: t("web.header.booking.membershipMenu.ProfileUpdate"),
                      itemProps: { leadingIcon: <AccountIcon />, onClick: () => router.push(`/member/profile`) },
                    },
                    {
                      label: t("web.header.booking.membershipMenu.MyCathayAccount"),
                      itemProps: { leadingIcon: <CathayIcon />, onClick: () => router.push(`/member/link-cx`) },
                    },
                  ],
                }
              : {}),
            ...(currencyOptionsShown
              ? {
                  currencyOptions,
                  currencyValue: globalSelectedCurrency,
                }
              : {}),
          }),
    }),
    [
      enteredB2b,
      t,
      agentProfile,
      tokenProfile,
      title,
      currencyOptionsShown,
      currencyOptions,
      globalSelectedCurrency,
      router,
      dispatch,
    ]
  );

  const localeOptions = useMemo(
    () =>
      SUPPORTED_LANGUAGES_WITH_LOCALES.map((locale) => ({
        value: locale,
        label: t(`web.general.locale.options.${locale}`),
        onChange: (option: SelectOption) => {
          changeLanguage(option.value);
        },
      })),
    [changeLanguage, t]
  );

  const onMobileAccountClick = useCallback(() => {
    dispatch(openSignInPopup());
  }, []);

  return (
    <Header
      logo={{
        header: (
          <Link href={enteredB2b ? "/b2b/agent-portal" : "/"}>
            <Image
              src={"/images/HKE - Logo.svg"}
              alt='logo'
              width={0}
              height={0}
              style={{ width: "auto", height: "100%" }}
            />
          </Link>
        ),
        mobileMenu: (
          <Link href={enteredB2b ? "/b2b/agent-portal" : "/"}>
            <Image
              src={"/images/HKE - Logo-white.svg"}
              alt='logo-white'
              width={0}
              height={0}
              style={{ width: "auto", height: "100%" }}
            />
          </Link>
        ),
      }}
      onMobileAccountClick={onMobileAccountClick}
      onLogout={onClickLogout}
      localeOptions={localeOptions}
      localeValue={locale}
      {...otherProps}
    />
  );
};

export default HeaderContainer;
