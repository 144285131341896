import { MemberState } from "./reducers";
import { CxProfile, GeneralProfile, TokenProfile } from "./types";

export const selectCxProfile = (state: { member: MemberState }): CxProfile | undefined => state.member.cxProfile;
export const selectGeneralProfile = (state: { member: MemberState }): GeneralProfile | undefined =>
  state.member.generalProfile;
export const selectIsLogin = (state: { member: MemberState }) => state.member.isLogin;
export const selectTokenProfile = (state: { member: MemberState }): TokenProfile | undefined =>
  state.member.tokenProfile;
// import { PassengerDetail, SearchingDetail, TripDetail } from "@hkexpressairwayslimited/ui";

// export const selectStepperActive = ({
//   flightBooking: { stepperIndex },
// }: {
//   flightBooking: FlightBookingReducerProps;
// }): number => stepperIndex;

// export const selectSearchingDetail = ({
//   flightBooking: { searchingDetail },
// }: {
//   flightBooking: FlightBookingReducerProps;
// }): SearchingDetail | undefined => searchingDetail;

// export const selectTripDetail = ({
//   flightBooking: { tripDetail },
// }: {
//   flightBooking: FlightBookingReducerProps;
// }): TripDetail | undefined => tripDetail;

export const selectPassengerDetail = ({ member }: { member: MemberState }) => {
  return member.memberDetails;
};

export const selectMilesInfo = ({ member }: { member: MemberState }) => {
  return member.milesInfo;
};
