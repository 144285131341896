import { LoadingBackdrop as MuiLoadingBackdrop } from "@hkexpressairwayslimited/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectLoadingBackdropCount } from "../../selectors/loadingBackdropSelector";

export interface LoadingBackdropProps {}

export function LoadingBackdrop(props: LoadingBackdropProps) {
  const count = useSelector(selectLoadingBackdropCount);

  const isOpen = useMemo(() => {
    return count > 0;
  }, [count]);

  return <MuiLoadingBackdrop isOpen={isOpen} />;
}
