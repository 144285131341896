import { getCookieDomain } from "@hkexpressairwayslimited/ui";
import { endpoints, fetchAPI } from "api";
import Cookies from "js-cookie";
import store from "store";
import { storeSelectedCurrency } from "store/sessionStorage/slices/currency";
import { CurrencyRate } from "./d";

export async function getCurrencyRate(currency: string): Promise<{ rate: CurrencyRate[] }> {
  return await fetchAPI(`${endpoints.currencyRate}?from_currency_code=${currency}`);
}

export async function changeSelectedCurrency(currency: string) {
  if (!currency) return;
  Cookies.set("currency", currency, { expires: 365, path: "/", domain: getCookieDomain() });
  store.dispatch(storeSelectedCurrency(currency));
}
