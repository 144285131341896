import { envConfig } from "env";

const initClientServiceWorker = async () => {
  if (envConfig.enableMockService) {
    if (typeof window !== "undefined") {
      const { worker, isEnableMockService } = require("./browser");

      if (isEnableMockService) {
        await worker.start();
      }
    }
  }
};

export default initClientServiceWorker;
