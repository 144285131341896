import { init } from "@sitecore/engage";

let engage: any;

const loadEngage = async () => {
  engage = await init({
    clientKey: "hkUaaV35xRHPpfmMrZN2gyS8WkgR3zM9", //"hkUaaV35xRHPpfmMrZN2gyS8WkgR3zM9",
    targetURL: "https://api-engage-ap.sitecorecloud.io",
    pointOfSale: "hkexpress.com",
    cookieDomain: window.location.hostname,
    cookieExpiryDays: 365,
    forceServerCookieMode: false,
    includeUTMParameters: true,
    webPersonalization: true /* boolean or object. See Settings object for all options. Default: false */,
  });

  // Expose Engage functions to the window object:
  window.Engage.exposedFunctions = engage;
};
loadEngage();

export { engage };
