import { rest } from "msw";
const contents_en = require("./contents_en.json");
const contents_zhCN = require("./contents_zhCN.json");
const contents_zhHK = require("./contents_zhHK.json");
const contents_ko = require("./contents_ko.json");
const contents_ja = require("./contents_ja.json");

export const contentApis = [
  rest.get("*content-management/list", (req, res, ctx) => {
    const lang = req.url.searchParams.get("lang");
    const contentObject = {
      en: contents_en,
      "en-HK": contents_en,
      "en-US": contents_en,
      "zh-HK": contents_zhHK,
      "zh-CN": contents_zhCN,
      "zh-TW": contents_zhCN,
      ko: contents_ko,
      ja: contents_ja,
    };
    const content = contentObject[lang as keyof typeof contentObject];
    return res(ctx.delay(1000), ctx.json({ content }));
  }),
];
