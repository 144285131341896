"use client";
import { Button, Font, Modal, ModalHandle } from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { ErrorType } from "lib/features/flight-book/payment/definition";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanErrorModalDetail, selectErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";

export const DetailErrorModal = () => {
  const { t } = useTransContent();
  const { desc, title, detailsElm, data, ctaText, onClose, onConfirm, type } =
    useSelector(selectErrorModalDetail) ?? {};
  const [ref, setFef] = useState<ModalHandle | null>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (desc && ref) {
      ref.open();
    }
  }, [desc, ref]);
  return (
    type === ErrorType.D &&
    desc &&
    title && (
      <Modal
        title={t(title)}
        onClose={() => {
          ref?.close();
          dispatch(cleanErrorModalDetail());
          onClose && onClose();
        }}
        ref={setFef}
      >
        <Stack rowGap={2} direction='column'>
          <Font>{t(desc)}</Font>
          {data && detailsElm && detailsElm(data)}
          {ctaText && (
            <Button
              fullWidth
              onClick={() => {
                ref?.close();
                dispatch(cleanErrorModalDetail());
                onConfirm && onConfirm();
              }}
            >
              {ctaText}
            </Button>
          )}
        </Stack>
      </Modal>
    )
  );
};
